// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$yaberry-primary: mat.define-palette(mat.$indigo-palette);
$yaberry-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$yaberry-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$yaberry-theme: mat.define-light-theme(
  (
    color: (
      primary: $yaberry-primary,
      accent: $yaberry-accent,
      warn: $yaberry-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($yaberry-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/typography";

:root {
  --background: #eae6e6;
  --default-black: #000;
  --default-black-rgb: rgb(0, 0, 0);
  --default-red: #ff3434;

  --font-ultra-light: "SF Pro Display Ultra Light Italic", sans-serif;
  --font-thin-italic: "SF Pro Display Thin Italic", sans-serif;
  --font-semi-bold-italic: "SF Pro Display Semi Bold Italic", sans-serif;
  --font-regular: "SF Pro Display Regular", sans-serif;
  --font-medium: "SF Pro Display Medium", sans-serif;
  --font-light-italic: "SF Pro Display Light Italic", sans-serif;
  --font-heavy-italic: "SF Pro Display Heavy Italic", sans-serif;
  --font-bold: "SF Pro Display Bold", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--background);
  font-size: 10px;
  font-family: var(--font-regular);
}

* {
  outline: none !important;
}

.hide {
  display: none !important;
}

.input {
  --internal-light-dark: transparent !important;
  background: transparent;
  border: none;
  width: 100%;
  height: var(--input-height, 4.6rem);
  font-family: var(--input-font-family, var(--font-bold));
  font-size: var(--input-font-size, 3rem);

  &.error {
    color: var(--default-red);
  }

  &:-internal-autofill-selected {
    font-size: 3rem;
    background-color: transparent !important;
  }

  &:-webkit-autofill {
    font-size: 3rem;
    background-color: transparent !important;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.font-ultra-light {
  font-family: var(--font-ultra-light);
}
.font-thin-italic {
  font-family: var(--font-thin-italic);
}
.font-semi-bold-italic {
  font-family: var(--font-semi-bold-italic);
}
.font--regular {
  font-family: var(--font-regular);
}
.font-medium {
  font-family: var(--font-medium);
}
.font-light-italic {
  font-family: var(--font-light-italic);
}
.font-heavy-italic {
  font-family: var(--font-heavy-italic);
}
.font-bold {
  font-family: var(--font-bold);
}

.white-color {
  color: #ffffff;
}
.black-color {
  color: var(--default-black);
}
.bg-black {
  background: var(--default-black);
}
.black-half-color {
  color: rgba(0, 0, 0, 0.5);
}
.gray-color {
  color: #908c8c;
}
.green-color {
  color: #24ad0e;
}
.red-color {
  color: #ff0c0c;
}
.yellow-color {
  color: #fbc660;
}
.dark-green-color {
  color: #00a99f;
}
.violet-color {
  color: #af2dff;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
